import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonComponentsModule } from './common-components/common-components.module';
import { BrandpageAccessGuard } from './guards/brandpage-access.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/landing-page/landing-page.module').then(
       (m) => m.LandingPagePageModule),
       canActivate: [BrandpageAccessGuard]
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./pages/my-account/my-account.module').then(
        (m) => m.MyAccountPageModule
      ),
  },
  {
    path: 'brandpage/:brandname',
    loadChildren: () =>
      import('./pages/brandpage/brandpage.module').then(
        (m) => m.BrandpagePageModule
      ),
  },
  {
    path: 'signin',
    loadChildren: () =>
      import('./pages/signin/signin.module').then((m) => m.SigninPageModule),
  },
  {
    path: 'congratulations',
    loadChildren: () =>
      import('./pages/congratulations/congratulations.module').then(
        (m) => m.CongratulationsPageModule
      ),
  },
  {
    path: 'scorepage/:id',
    loadChildren: () =>
      import('./pages/scorepage/scorepage.module').then(
        (m) => m.ScorepagePageModule
      ),
  },
  {
    path: 'scorepage',
    loadChildren: () =>
      import('./pages/scorepage/scorepage.module').then(
        (m) => m.ScorepagePageModule
      ),
  },
  {
    path: 'spotplay/:playId',
    loadChildren: () =>
      import('./pages/spotplay/spotplay.module').then(
        (m) => m.SpotplayPageModule
      ),
  },
  {
    path: 'profilepage/:userId',
    loadChildren: () =>
      import('./pages/profilepage/profilepage.module').then(
        (m) => m.ProfilePageModule
      ),
  },
  {
    path: 'profilemainscreen/:userId',
    loadChildren: () =>
      import('./pages/profilemainscreen/profilemainscreen.module').then(
        (m) => m.ProfilemainscreenPageModule
      ),
  },
  {
    path: 'coupon-details/:participationId',
    loadChildren: () =>
      import('./pages/coupon-details/coupon-details.module').then(
        (m) => m.CouponDetailsPageModule
      ),
  },
   {
    path: 'coupon-details',
    loadChildren: () =>
      import('./pages/coupon-details/coupon-details.module').then(
        (m) => m.CouponDetailsPageModule
      ),
  },
  {
    path: 'coupon-redeem',
    loadChildren: () =>
      import('./pages/coupon-redeem/coupon-redeem.module').then(
        (m) => m.CouponRedeemPageModule
      ),
  },
  {
    path: 'landing-page',
    loadChildren: () => import('./pages/landing-page/landing-page.module').then(
       (m) => m.LandingPagePageModule),
       canActivate: [BrandpageAccessGuard]
  },
  {
    path: 'tournament-page/:campaignId',
    loadChildren: () => import('./pages/tournament-page/tournament-page.module').then( m => m.TournamentPagePageModule)
  },
  {
    path: 'leaderbay/:campaignId',
    loadChildren: () => import('./pages/leaderbay/leaderbay.module').then( m => m.LeaderbayPageModule)
  },
  {
    path: 'usercoupons',
    loadChildren: () => import('./pages/usercoupons/usercoupons.module').then( m => m.UsercouponsPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
    CommonComponentsModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
