// import { HttpClient } from '@angular/common/http';
// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-root',
//   templateUrl: 'app.component.html',
//   styleUrls: ['app.component.scss'],
// })
// export class AppComponent {
//   constructor(public httpClient: HttpClient) {}
// }

import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Constants } from './models/constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loginExpired: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object 
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe((params) => {
        const key = params['key'];
        if (key) {
          this.magicLogin(key);
        }
      });
    }
  }

  magicLogin(key: string) {
    const apiUrl = Constants.AUTH_API.magicLoginByHashCode + key;

    this.httpClient.post<any>(apiUrl, {}).subscribe(
      (response) => {
        if (response && response.authStatus === 'Expired') {
          this.loginExpired = true;
        } else if (response && response.userId && response.token) {
          localStorage.setItem('userId', response.userId);
          localStorage.setItem('token', response.token);
          localStorage.setItem('logedin', 'true');

          this.removeKeyFromUrl();
        }
      },
      (error) => {
        console.error('Error during magic login:', error);
      }
    );
  }

  removeKeyFromUrl() {
    const url = this.router.url.split('?')[0];
    window.history.replaceState({}, '', url);
  }
}



