/* eslint-disable @typescript-eslint/naming-convention */
export class Constants {
  // static GATEWAY_ENDPOINT = 'http://localhost:8080';
  static GATEWAY_ENDPOINT = 'https://api.spotbay.in';
  //static GATEWAY_ENDPOINT = 'https://sandbox-gateway.spotbay.co';
  static AUTH_SERVICE = Constants.GATEWAY_ENDPOINT + '/auth/';
  static CONTEST_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbaycontests/';
  static QUIZ_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbayquiz/';
  static PROFILE_SERVICE = Constants.GATEWAY_ENDPOINT + '/spotbayprofile/';
  static PARTICIPATION_SERVICE =
    Constants.GATEWAY_ENDPOINT + '/spotbayParticipation/';
  // static NOTIFICATION_SERVICE = Constants.GATEWAY_ENDPOINT + '/notifications/';

  static AUTH_API = {
    login: Constants.AUTH_SERVICE + 'login',
    verify: Constants.AUTH_SERVICE + 'verify',
    skipotp: Constants.AUTH_SERVICE + 'skipotp',
    magicLoginByHashCode: 'https://api.spotbay.in/auth/hashcode?hashCode=',

    // addProfile: Constants.PROFILE_SERVICE + 'addprofile',
  };

  // static NOTIFICATION_API = {
  //   sendSms: Constants.NOTIFICATION_SERVICE + 'sendSMSNotification',
  // };

  static QUIZ_API = {
    quizByTags: Constants.QUIZ_SERVICE + 'getQuizByTag?tags=',
  };
  static PARTICIPATION_API = {
    getScoreByParticipationId:
      Constants.PARTICIPATION_SERVICE + 'getScoreByParticipationId/',
    getAllCampaigns: Constants.PARTICIPATION_SERVICE + 'getAllCampaigns',
    getCampaignDetails:
      Constants.PARTICIPATION_SERVICE + 'getCampaignDetailByCampaignId/',
    getUserCouponsByUserId:
      Constants.PARTICIPATION_SERVICE + 'getUserCouponsByUserId/',
      getAllAssignedContestsForCampaign:
      Constants.PARTICIPATION_SERVICE + 'getAllAssignedContestsForCampaign/',
      getCampaignUserScoreDetails:
      Constants.PARTICIPATION_SERVICE + 'getCampaignUserScoreDetails/',
      getCampaignDetailsByContestId:
      Constants.PARTICIPATION_SERVICE + 'getCampaignDetailsByContestId/',
      getAllAssignedContestsForCampaignByUserId:
      Constants.PARTICIPATION_SERVICE + 'getAllAssignedContestsForCampaignByUserId/',
      getCouponDetailsByParticipationId:
      Constants.PARTICIPATION_SERVICE + 'getCouponDetailsByParticipationId/',
  };
  static CONTEST_API = {
    allContests: Constants.CONTEST_SERVICE + 'allContests',
    submitScore: Constants.CONTEST_SERVICE + 'submitScore',
    contestsById: Constants.CONTEST_SERVICE + 'getContestsById/',
    contestsByLabel: Constants.CONTEST_SERVICE + 'getContestsInfo/',
    contestsForUser: Constants.CONTEST_SERVICE + 'getContestsForUser/',
    getPageAd: Constants.CONTEST_SERVICE + 'getPageAd/',
    videoContestsByContesId: Constants.CONTEST_SERVICE + 'getVideoContestInfo/',
    getPublicContests: Constants.CONTEST_SERVICE + 'getAllActivePublicContests',
    publicContestsForUser: Constants.CONTEST_SERVICE +'getPublicContestsForUser/'
  };

  static GIFT_API = {};

  static PROFILE_API = {
    viewProfile: Constants.PROFILE_SERVICE + 'viewProfile/',
    updateProfile: Constants.PROFILE_SERVICE + 'updateProfile/',
    deleteuser: Constants.PROFILE_SERVICE + 'deleteUserAccount/',
  };

  static MESSAGES = {
    mobileValidationFailed: 'Enter a valid mobile Number',
    nameValidationFailed: 'Enter a valid Name',
    otprequired: 'Enter OTP to proceed',
    otpverificationfailed: 'OTP verification failed',
    labelValidationFailed: 'Enter a valid Brand Label',
    termsandpolicyFailed: 'Check Terms & Policy document ',
  };
}
