import { Component, OnInit } from '@angular/core';
import { SoundService } from 'src/app/services/sounds/sound.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  muted = this.soundService.soundsOn;
  constructor(private soundService: SoundService) {}

  ngOnInit() {}

  toggleMute() {
    this.soundService.soundsOn = !this.soundService.soundsOn;
    if (!this.soundService.soundsOn) {
      this.soundService.muteAll();
    } else {
      this.soundService.unMuteAll();
    }
    this.muted = this.soundService.soundsOn;
  }
}
