import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-text',
  templateUrl: './loading-text.component.html',
  styleUrls: ['./loading-text.component.scss'],
})
export class LoadingTextComponent implements OnInit {
  @Input() loadingText = '';
  constructor() { }

  ngOnInit() {}

}
