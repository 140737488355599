import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { HeaderComponent } from './header/header.component';
import { TournamentHeaderComponent } from './header/tournament-header/tournament-header.component';
import { LoadingTextComponent } from './loading-text/loading-text.component';

@NgModule({
  declarations: [LoadingComponent, HeaderComponent,TournamentHeaderComponent,LoadingTextComponent],
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [LoadingComponent, HeaderComponent,TournamentHeaderComponent,LoadingTextComponent],
})
export class CommonComponentsModule {}
