import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  soundsOn = true;
  melodyLoopOn = false;

  buttonTap = new Audio();
  melodyloopSound = new Audio();
  tapSound = new Audio();
  timeUpSound = new Audio();
  optionsInSound = new Audio();
  optionTimerSound = new Audio();
  rightAnswerSound = new Audio();
  wrongAnswerSound = new Audio();

  constructor() {
    this.buttonTap.src = '../../../assets/sounds/buttontap.mp3';
    this.buttonTap.load();
  }

  playButtonTapSound() {
    if (this.soundsOn) {
      this.buttonTap.play();
    }
  }

  initQuizSounds() {
    this.melodyloopSound.loop = true;
    this.melodyloopSound.volume = 0.09;
    this.melodyloopSound.src = '../../../assets/sounds/melody_loop.mp3';
    this.melodyloopSound.load();

    //this.tap.volume = 0.2;
    this.tapSound.src = '../../../assets/sounds/stapler.mp3';
    this.tapSound.load();

    //this. timeUpSound =;
    this.optionsInSound.src = '../../../assets/sounds/count5to1.mp3';
    this.optionsInSound.volume = 0.09;
    this.optionsInSound.load();

    this.optionTimerSound.src = '../../../assets/sounds/ambientdanger.mp3';
    this.optionTimerSound.load();

    this.rightAnswerSound.src = '../../../assets/sounds/rightanswer.mp3';
    this.rightAnswerSound.volume = 0.06;
    this.rightAnswerSound.load();

    this.wrongAnswerSound.src = '../../../assets/sounds/wronganswer.mp3';
    this.wrongAnswerSound.volume = 0.06;
    this.wrongAnswerSound.load();
  }

  resetOptionsInSound() {
    this.optionsInSound.pause();
    this.optionsInSound.load();
  }

  resetOptionTimerSound() {
    this.optionTimerSound.pause();
    this.optionTimerSound.load();
  }

  muteAll() {
    this.buttonTap.pause();
    this.buttonTap.volume = 0;

    this.melodyloopSound.pause();
    this.melodyloopSound.volume = 0;

    this.tapSound.pause();
    this.tapSound.volume = 0;

    this.timeUpSound.pause();
    this.timeUpSound.volume = 0;

    this.optionsInSound.pause();
    this.optionsInSound.volume = 0;

    this.optionTimerSound.pause();
    this.optionTimerSound.volume = 0;

    this.rightAnswerSound.pause();
    this.rightAnswerSound.volume = 0;

    this.wrongAnswerSound.pause();
    this.wrongAnswerSound.volume = 0;
  }

  unMuteAll() {
    this.buttonTap.volume = 1;
    if (this.melodyLoopOn) {
      this.melodyloopSound.play();
    }

    this.melodyloopSound.volume = 0.09;

    this.tapSound.volume = 1;

    this.timeUpSound.volume = 1;

    this.optionsInSound.volume = 0.2;

    this.optionTimerSound.volume = 1;

    this.rightAnswerSound.volume = 0.06;

    this.wrongAnswerSound.volume = 0.06;
  }
}
