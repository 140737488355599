import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tournament-header',
  templateUrl: './tournament-header.component.html',
  styleUrls: ['./tournament-header.component.scss'],
})
export class TournamentHeaderComponent implements OnInit {
  @Input() campaignId: any;

  constructor(private router: Router) {}

  ngOnInit() {
  }

  goToScoreDetails() {
    this.router.navigate(['/leaderbay', this.campaignId]);
  }
}
