import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandpageAccessGuard implements CanActivate {

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (isPlatformBrowser(this.platformId)) {
      const lastVisitTime = localStorage.getItem('brandPageAccessTime');
      const currentTime = new Date().getTime();

      if (lastVisitTime) {
        const timeElapsed = currentTime - parseInt(lastVisitTime, 10);

        if (timeElapsed < 3600000) {
          alert('Access to this page is restricted for 1 hour.');
          const label = localStorage.getItem('lastVisitedBusinessLabel');
          return this.router.createUrlTree(['/brandpage', label]);
        }
      } else {
        localStorage.setItem('brandPageAccessTime', currentTime.toString());
      }
    }

    return true;
  }
}
